import './App.css';
import DeliveryForm, { DeliveryFormData } from './components/DeliveryForm';
import useDeliveryFee from './hooks/useDeliveryFee';
import styled from 'styled-components';

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    margin: auto;
    padding: 2rem 0;

    @media (min-width: 600px) {
        background-color: rgb(0, 157, 224);
    }

    @media (min-width: 800px) {
        flex-direction: row;
    }
`;

const Card = styled.div`
    padding: 1.5rem;
    @media (min-width: 600px) {
        background: white;
        padding: 2.5rem;
        box-shadow: 2px 4px 16px 4px #00000042;
        border-radius: 1rem;
        border: 1px solid black;
    }
`;

const BackgroundImg = styled.img`
    width: 80%;
    // this is just a workaround because i don't have the time to cut the image
    margin-left: -15%;
    @media (min-width: 800px) {
        width: 40%;
    }
`;

const Title = styled.h1`
    margin-bottom: 8%;
    margin-top: 0px;
    text-align: center;
    font-family: monospace;
    font-size: 2rem;
`;

const FeeText = styled.p`
    margin-top: 8%;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
`;

function App() {
    const { fee, updateDeliveryData } = useDeliveryFee();

    const handleFormSubmit = (data: DeliveryFormData) => {
        updateDeliveryData(data);
    };

    return (
        <ContentWrapper>
            <BackgroundImg
                src='https://imageproxy.wolt.com/static-assets/frontpage-assets/wolt-plus/banner_wolt_plus.png'
                alt=''
            />
            <Card>
                <Title>Delivery Fee Calculator</Title>
                <DeliveryForm onSubmit={handleFormSubmit} />
                {fee != null && (
                    <FeeText aria-live='polite'>Delivery price: {fee}€</FeeText>
                )}
            </Card>
        </ContentWrapper>
    );
}

export default App;
