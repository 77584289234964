import React from 'react';
import styled from 'styled-components';

interface DeliveryFormProps {
    onSubmit: (data: DeliveryFormData) => void;
}

export interface DeliveryFormData {
    cartValue: number;
    deliveryDistance: number;
    numberOfItems: number;
    orderTime: string;
}
interface DeliveryFormInputData {
    cartValue: string;
    deliveryDistance: string;
    numberOfItems: string;
    orderTime: string;
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const InputLayout = styled.div`
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 12px;
    align-items: center;
    width: 100%;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const Label = styled.label`
    font-size: 1rem;
`;

const Input = styled.input`
    padding: 8px;
`;

const SubmitButton = styled.button`
    padding: 16px;
    width: 100%;
    margin-top: 8%;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    background-color: #002c64;
    color: #f2f2f6;

    &:hover {
        background-color: #084089;
    }
    &:focus {
        outline-color: #009de0;
    }
    &:active {
        background-color: #011d43;
    }
`;

export default function DeliveryForm({ onSubmit }: DeliveryFormProps) {
    const [formData, setFormData] = React.useState<DeliveryFormInputData>({
        cartValue: '',
        deliveryDistance: '',
        numberOfItems: '',
        orderTime: '',
    });

    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const data: DeliveryFormData = {
            cartValue: Number.parseFloat(formData.cartValue),
            deliveryDistance: Number.parseFloat(formData.deliveryDistance),
            numberOfItems: Number.parseFloat(formData.numberOfItems),
            orderTime: formData.orderTime,
        };

        onSubmit(data);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <InputLayout>
                <Label htmlFor='cartValue'>Cart Value:</Label>
                <Input
                    type='number'
                    name='cartValue'
                    id='cartValue'
                    data-test-id='cartValue'
                    min={0.0}
                    step={0.01}
                    placeholder='Enter cart amount in €'
                    required
                    value={formData.cartValue}
                    onChange={handleInputChange}
                />
                <Label htmlFor='deliveryDistance'>Delivery distance:</Label>
                <Input
                    type='number'
                    data-test-id='deliveryDistance'
                    name='deliveryDistance'
                    id='deliveryDistance'
                    min={0.0}
                    step={0.01}
                    placeholder='Enter delivery distance in meters'
                    required
                    value={formData.deliveryDistance}
                    onChange={handleInputChange}
                />
                <Label htmlFor='numberOfItems'>Number of items:</Label>
                <Input
                    type='number'
                    name='numberOfItems'
                    id='numberOfItems'
                    data-test-id='numberOfItems'
                    min={0}
                    placeholder='Enter number of items'
                    required
                    value={formData.numberOfItems}
                    onChange={handleInputChange}
                />
                <Label htmlFor='orderTime'>Order date and time:</Label>
                <Input
                    type='datetime-local'
                    name='orderTime'
                    id='orderTime'
                    data-test-id='orderTime'
                    required
                    value={formData.orderTime}
                    onChange={handleInputChange}
                />
            </InputLayout>

            <SubmitButton type='submit'>Calculate delivery price</SubmitButton>
        </Form>
    );
}
